import './css/main.css'

function ready()
{
  console.log("Test3");
  let isLive = false;
  let isChat = false;

  let liveTime = 0;
  let chatTime = 0;
  let interval = undefined;

  function goLive()
  {

  }

  function countdownTick()
  {
    if (interval === undefined) {
      interval = setInterval(countdownTick, 1000);
    }

    const label = document.getElementById('video-player-countdown-label');
    const time = document.getElementById('video-player-countdown-time');

    let secondsRemaining = liveTime - (Date.now() / 1000.0);
    let chatSecondsRemaining = chatTime - (Date.now() / 1000.0);

    secondsRemaining = Math.max(secondsRemaining, 0)
    chatSecondsRemaining = Math.max(chatSecondsRemaining, 0)

    if (secondsRemaining <= 0) {
      if (!isLive) {
        isLive = true;
        const wrapper = document.getElementById('stream-video');    
        const placeholder = document.getElementById('placeholder-video');
        document.getElementById('video-muted-button').style.display = "none";
        placeholder.style.display = "none";
        wrapper.style.display = "block";

        setTimeout(function() {
          const iframe = document.createElement('iframe');
          iframe.setAttribute('id', 'bright-player');
          iframe.allow = "fullscreen; autoplay"
          iframe.src = 'https://my.icareus.com/web/bright/player/embed/webcast?eventId=54037471&playerId=54037444&videomax=true';
          iframe.scrolling = "no";
          iframe.overflow = "hidden";
          wrapper.appendChild(iframe);
        }, 1);
      }
    } else {
    }

    if (chatSecondsRemaining <= 0) {
      if (!isChat) {
        isChat = true;
        label.style.display = "none";
        time.innerText = "LIVE";

        document.getElementById('radio').style.display = "none";
        document.getElementById('chat').style.display = "block";

        const iframe2 = document.createElement('iframe');
        iframe2.src = 'https://interactive.brightgroup.com/bright3/';
        document.getElementById('message').appendChild(iframe2);

        const iframe3 = document.createElement('iframe');
        iframe3.src = 'https://interactive.brightgroup.com/bright3/wall';
        document.getElementById('wall').appendChild(iframe3);

        document.getElementById('heart3').style.display = "block";
        document.getElementById('heart7').style.display = "none";

        const radioPlaceholder = document.getElementById('radio-placeholder');
        radioPlaceholder.innerHTML = "";
      }
    } else {
      label.style.display = "inline";
      let secs = chatSecondsRemaining + 60;
      let hours = Math.floor(secs / 3600);
      secs = secs % 3600;
      let minutes = Math.floor(secs / 60);
      secs = secs % 60;

      hours = hours.toString().padStart(2, '0');
      minutes = minutes.toString().padStart(2, '0');

      time.innerText = hours + ":" + minutes;
    }

    time.style.display = "inline";
  }

  function beginCountdown()
  {
    countdownTick();
  }

  function getCountdown()
  {
    var oReq = new XMLHttpRequest();
    oReq.addEventListener("load", () => {
      chatTime = Math.floor(Date.now() / 1000.0) + parseInt(oReq.responseText);

      const urlParams = new URLSearchParams(window.location.search);
      const live = urlParams.get('live');

      liveTime = chatTime - 14400;

      if (live) {
        liveTime = Math.floor(Date.now() / 1000.0) + 30;
        chatTime = liveTime + 30;
      }

      beginCountdown();
    });
    oReq.open("GET", "https://forms.campaigns.fi/sanoma.php");
    oReq.send();
  }

  const video = document.getElementById('placeholder-video');
  let readyToPlayFired = false;
  
  function videoReadyToPlay(event)
  {
    if (readyToPlayFired) {
      return;
    }

    readyToPlayFired = true;

    video.removeEventListener('canplay', videoReadyToPlay);
    const promise = video.play();

    if (promise !== undefined) {
      promise.then(_ => {
        getCountdown();
      }).catch(error => {
        video.muted = true;
        video.play();
        getCountdown();
        document.getElementById('video-muted-button').style.display = "block";
      });
    } else {
      video.muted = true;
      video.play();
      getCountdown();
      document.getElementById('video-muted-button').style.display = "block";
    }
  }

  video.addEventListener('canplay', videoReadyToPlay);
  video.addEventListener('loadedmetadata', videoReadyToPlay);

  const mutedButton = document.getElementById('video-muted-button');
  mutedButton.addEventListener('click', function(e) {
    e.preventDefault();
    mutedButton.style.display = 'none';

    video.muted = false;

    if (isLive) {
      document.getElementById('bright-player').src += '';
    }
  });

  
  document.getElementById('radio-play-button').addEventListener('click', function(e) {
    e.preventDefault();

    document.getElementById('video-muted-button').style.display = "none";

    const radioPlaceholder = document.getElementById('radio-placeholder');
    radioPlaceholder.innerHTML = "";

    var params = {
      containerId: "radio-placeholder",
      mediaId: "2278513",
      autoplay: true,
      supla: true,
      fw_site: "Embedded",
      ns_site: "sanoma-external"
    };
    RP.embedPlayer(params);
  });
}

if (document.readyState === "complete" || document.readyState === "interactive") {
  setTimeout(ready, 0);
} else {
  document.addEventListener("DOMContentLoaded", ready);
}